import axios from "axios";

const request =axios.create({
    timeout:30000,
    baseURL:"/hm"
})


request.interceptors.request.use(config=>{
    return config
},err=>{
    console.log(err)
    return err
})
request.interceptors.response.use(res=>{
    // if(res.data.code==0){
    //     return res
    // }else{
    //     console.log(res)
    // }
    return res
},err=>{
    console.log(err)
})
export default request