<template>
  <div id="app">
	<index v-if="orientation === '1'" ></index>
  <vertical v-else/>
  </div>
</template>

<script>
import index from './view/index/indexInfo.vue'
import vertical from './view/index/vertical.vue'
import { getUrlParams } from "../utils/qin-ui";
import {  exchangeUploadToken } from "./api/api.js";
export default {
  name: 'app',
  components: {
    index,
    vertical
  },
  data() {
    return {
      tempToken: "",
      accountId: "",
      orientation: ""
    }
  },
  created() {
    let href = window.location.href;
    // 测试链接
    // href = "https://yhbmo.haierzhongyou.com/?tempToken=1c399d8e96c34532af8cdb23055991f3&accountId=5d867bfdaad844f5b34348fdd689063f&orientation=1"

    href = href.substring(0, href.length);
    const hrefObj = getUrlParams(href);
    console.log(hrefObj);
    this.orientation = hrefObj.orientation;
  },
  methods: {
    getQiniuToken() {
      exchangeUploadToken({ tempToken: this.tempToken, accountId: this.accountId }).then(
          (res) => {
            console.log(res.data.data);
            const qiniuInfo = res.data.data;
            console.log(this.qiniuInfo);
            sessionStorage.setItem('qiniuInfo', JSON.stringify(qiniuInfo));
          }
        );
    },
  }
}
</script>

<style>
#app {
 
}
</style>
