// GET /hm/apk/picUpload/getAccountAllPic/{accountId}
import request from "../../utils/request"

export function getId(id){
    return request({
        url:'/app/picUpload/getAccountAllPic/'+id,
        method:"get",
	// 	  headers: {
	// 	      'accountId': id
	// 	    }
	
    })
}
export function exchangeUploadToken(data){
    return request({
        url:'/app/picUpload/exchangeUploadToken',
        method:"get",
        params:data
	
    })
}

export function uploadUrl(data){
    return request({
        url:`/app/picUpload/uploadUrl`,
        method:"get",
        params:data
	
    })
}
// 删除画册图片
export function deletePic(id){
    return request({
        url:`/app/picUpload/delete/` + id,
        method:"delete"
    })
}

// 保存我的画册
export function savePic(data){
    return request({
        url:`/app/picUpload/save`,
        method:"post",
        data
	
    })
}
